@font-face {
  font-family: 'Almoni neue aaa';
  src: url('/fonts/almoni-neue-bold-aaa.woff2') format('woff2'), url('/fonts/almoni-neue-bold-aaa.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Almoni neue aaa';
  src: url('/fonts/almoni-neue-regular-aaa.woff2') format('woff2'), url('/fonts/almoni-neue-regular-aaa.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


body {
  margin: 0;
  font-family: Almoni neue aaa, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button, input, textarea {
  font-family: Almoni neue aaa, sans-serif;
}

.ant-form-item-label > label {
  font-size: 1.5em;
}
