.root {
    padding: 12px 24px;
    border-style: solid;
    border-width: 1px;
    border-color: #7928d6;
    border-radius: 100px;
    background-color: transparent;
    color: #7928d6;
    font-size: 1.25em;
    line-height: 1;
    font-weight: 700;
}

.input {
    display: none;
}