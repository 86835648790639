.root {
    border-style: solid;
    border-width: 1px;
    border-color: #acb2b6;
    text-align: right;

    &[type=time] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    
    background: white;
    padding: 0.7em 0.75em;
    
    width: 100%;

    font-size: 1.2em;
    line-height: 1;


    &:focus {
        outline: 0;
        border-color: #3898EC;
    }
}