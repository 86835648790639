.root {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-block-end: 80px;
}

.section {
    padding-top: 2.5em;
    padding-bottom: 2.5em;
    max-width: 660px;
    width: 100%;

    &__title {
        text-align: center;
    }
}

.whitespace {
    margin-block-start: 6em;
}

.primaryButton {
    margin-block-start: 80px;
}

.submitting {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    .icon {
        font-size: 3.5em;
    }

    > span {
        margin-block-start: 1em;
        font-size: 1.5em;
    }
}