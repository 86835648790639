.root {
    box-shadow: inset 0 2px 0 0 #fff;
    margin-block-start: 2em;
    padding-block-start: 24px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: 991px) {
        margin-inline-start: -24px;
        margin-inline-end: -24px;
        padding-inline-start: 24px;
        padding-inline-end: 24px;
    }

    @media only screen and (min-width: 992px) {
        margin-inline-start: -40px;
        margin-inline-end: -40px;
        padding-inline-start: 40px;
        padding-inline-end: 40px;
    }
}

.subtitle {
    color: rgba(51, 51, 51, 0.7);
    font-size: 1.2em;
    line-height: 1.5;
}

.upload {
    margin-block-start: 1em;
}

.play {
    margin-block-start: 1.7em;
    margin-block-end: 1.7em;
}

.select {
    margin-block-start: 1.2em;
}