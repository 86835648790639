.root {

    display: block;
    padding-block-start: 0.5em;
    padding-block-end: 0.5em;

    &.small {
        .label {
            font-size: 1.2em;
        }
    }

    .label {
        display: block;
        font-size: 1.5em;
        line-height: 1.5;
        font-weight: 400;
    }

    .description {
        display: block;
        margin-bottom: 0.5em;
        color: rgba(51, 51, 51, 0.7);
        font-size: 1.2em;
        line-height: 1.5;
    }

    .error {
        color: #ff0033;
        font-size: 1.2em;
    }
}