.root {
    margin-block-start: 2em;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.subtitle {
    color: rgba(51, 51, 51, 0.7);
    font-size: 1.2em;
    line-height: 1.5;
}

.switch {
    display: flex;
    align-items: center;

    > span {
        margin-inline-end: 8px;
        font-size: 1.2em;
    }
}