.subtitle {
    color: rgba(51, 51, 51, 0.7);
    font-size: 1.2em;
    line-height: 1.5;
}

.collapsed {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.root {
    margin-top: 1.5em;
    background-color: #f4f1ff;

    @media only screen and (max-width: 991px) {
        margin-right: -24px;
        margin-left: -24px;
        padding: 24px;
    }

    @media only screen and (min-width: 992px) {
        border-radius: 20px;
        margin-right: -40px;
        margin-left: -40px;
        padding: 40px;
    }
}

.expand-icon {
    font-size: 2.3em;
    color: #7928d6;
}