.root {
    border: none;
    border-radius: 40px;

    padding: 0;

    width: 80px;
    height: 80px;

    background: #7928d6;

    color: white;

    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
    font-size: 24px;
    transform: scale(1.5);
    color: white;
}