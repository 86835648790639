html, body {
  font-size: 14px;
  direction: rtl;
  color: #333;
}

* {
  box-sizing: border-box;
}

h1 {
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  line-height: 0.9;
  font-weight: 700;

  @media only screen and (max-width: 991px) {
    font-size: 3.5em;
  }

  @media only screen and (min-width: 992px) {
    font-size: 6em;
  }
}

h2 {
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 1.125;
  font-weight: 700;

  @media only screen and(max-width: 991px) {
    font-size: 2em;
  }

  @media only screen and (min-width: 992px) {
    font-size: 2.3em;
  }
}

h3 {
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 1.25;

  @media only screen and (max-width: 991px) {
    font-size: 1.5em;
    font-weight: 400;
  }

  @media only screen and (min-width: 992px) {
    font-size: 1.7em;
    font-weight: 700;
  }
}

h5 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.5em;
  line-height: 1.5;
  font-weight: 700;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}

.section {

  @media only screen and (max-width: 991px) {
    padding-inline-start: 24px;
    padding-inline-end: 24px;
  }

  @media only screen and (min-width: 992px) {
    padding-inline-start: 80px;
    padding-inline-end: 80px;
  }
}

::placeholder {
  color: #9d9d9d;
}

button {
  cursor: pointer;

  &.primary {
    border: 0;
    padding: 24px 80px;
    border-radius: 100px;
    background-color: #7928d6;
    font-size: 1.5em;
    line-height: 1;
    font-weight: 700;
    color: white;
  }

  &.dashed {
    padding: 12px 24px;
    border-style: dashed;
    border-width: 1px;
    border-color: #7928d6;
    border-radius: 100px;
    background-color: transparent;
    color: #7928d6;
    font-size: 1.25em;
    line-height: 1;
    font-weight: 700;
    
  }
}
.success-message {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > p {
    font-size: 1.3em;
  }
}