.root {
  background-image: linear-gradient(180deg, rgba(47, 26, 202, 0.3) 18%, #6d2ad8), url('/cover.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  margin-block-end: 80px;

  color: white;

  @media only screen and (max-width: 991px) {
    padding-block-start: 24px;
    padding-block-end: 24px;
  }

  @media only screen and (min-width: 992px) {
    padding-block-start: 80px;
    padding-block-end: 80px;
  }
}

.logo {
  @media only screen and (max-width: 991px) {
    width: 160px;
  }

  @media only screen and (min-width: 992px) {
    width: 200px;
  }
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  text-align: center;

  @media only screen and (max-width: 991px) {
    max-width: 360px;
    padding-block-start: 2.5em;
    padding-block-end: 2.5em;
  }

  @media only screen and (min-width: 992px) {
    padding: 5em;
    max-width: 660px;
  }
}

.title {

  @media only screen and (max-width: 991px) {
    padding-inline-start: 0.5em;
    padding-inline-end: 0.5em;
  }
}

.subtitle {
  @media only screen and (min-width: 992px) {
    padding-inline-start: 32px;
    padding-inline-end: 32px;
  }
}